<template id="questions">
  <div>
    <div v-if="screenType === 'desktop'">
      <div>
        <v-snackbar
            top
            v-model="snackbar"
        >
          <div v-html="msg">

          </div>
          <template v-slot:action="{ attrs }">
            <v-btn
                color="red"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <q-b-header></q-b-header>
        <exam-header @changeExamType="selectedExamType = $event" :selectedExamType="selectedExamType"></exam-header>
        <exam-predefined v-if="selectedExamType === 'predefined'"></exam-predefined>
        <div v-if="selectedExamType === 'custom'" class="customized-exam-container">
          <v-row>
            <v-col cols="5">
              <div class="text-left title-custom-question-page">
                Exam Mode
              </div>
              <div class="background-element-exam">
                <v-radio-group
                    class="mx-auto"
                    row
                    v-model="examType"
                >
                  <v-radio
                      color="#555e66"
                      class="mx-auto mt-3"
                      label="Timed"
                      value=time
                  ></v-radio>
                  <v-radio
                      color="#555e66"
                      class="mx-auto mt-3"
                      label="Untimed"
                      value=liveTutor
                  ></v-radio>
                  <v-radio
                      color="#555e66"
                      class="mx-auto mt-3"
                      label="Tutor"
                      value=tutor
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-col>
            <v-col cols="7" class="pl-5">
              <div class="text-left title-custom-question-page" style="margin-bottom: 17px!important">
                Number of questions
              </div>
              <div class="background-element-exam">
                <v-slider
                    min="1"
                    class="my-0 py-0"
                    track-color="white"
                    track-color-filled="#555e66"
                    max="40"
                    color="#555e66"
                    v-model="questionNumber"
                    :thumb-size="20"
                    thumb-label="always"
                ></v-slider>
                <div style="margin-top:-18px!important">
                  <a href="#" @click="--questionNumber" class="mx-10 my-0 py-0">
                    <v-icon color="#a8a8a8">mdi-chevron-left</v-icon>
                  </a>
                  <span class="my-0 py-0" style="font-size: 16px;color:#6d6d6d">{{ questionNumber }}</span>
                  <a href="#" @click="++questionNumber" class="mx-10 my-0 py-0">
                    <v-icon color="#a8a8a8">mdi-chevron-right</v-icon>
                  </a>
                </div>

              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pl-3">
              <div class="text-left title-custom-question-page" style="margin-bottom: 26px!important">
                Question Type
              </div>
              <div class="background-element-exam">
                <v-row class="px-16">
                  <v-col sm="6" md="3">
                    <v-switch
                        v-model="all"
                        color="#39bf88"
                        :label="'All'+' ('+counter.all+')'"
                        :value=true
                        @click="setAll"
                    ></v-switch>
                  </v-col>
                  <v-col sm="6" md="3">
                    <v-switch
                        v-model="marked"
                        color="#39bf88"
                        :label="'Marked'+' ('+counter.marked+')'"
                        :value=true
                        @click="setMark"
                    ></v-switch>
                  </v-col>
                  <v-col sm="6" md="3">
                    <v-switch
                        v-model="unused"
                        color="#39bf88"
                        :label="'Unused'+' ('+counter.unused+')'"
                        :value=true
                        @click="setUnused"
                    ></v-switch>
                  </v-col>
                  <v-col sm="6" md="3">
                    <v-switch
                        v-model="incorrect"
                        color="#39bf88"
                        :label="'Incorrect'+' ('+counter.incorrect+')'"
                        :value=true
                        @click="setIncorrect"
                    ></v-switch>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <!--      <v-row>-->
          <!--        <v-col class="background-color mt-10" cols="12">-->
          <!--          <v-expansion-panels>-->
          <!--            <v-expansion-panel-->
          <!--            >-->
          <!--              <v-expansion-panel-header disable-icon-rotate>-->
          <!--                <div v-if="!categoryIds[0]">-->
          <!--                  Select Category-->
          <!--                </div>-->
          <!--                <div v-else>-->
          <!--                          <span style="font-size: 20px;font-family:Blinker-SemiBold; color:limegreen">{{-->
          <!--                              categoryIds.length-->
          <!--                            }} </span> Category Selected-->
          <!--                </div>-->
          <!--                <template v-if="categoryIds[0]" v-slot:actions>-->
          <!--                  <v-icon color="teal">-->
          <!--                    mdi-check-->
          <!--                  </v-icon>-->
          <!--                </template>-->
          <!--                <template v-else v-slot:actions>-->
          <!--                  <v-icon color="red">-->
          <!--                    mdi-alert-->
          <!--                  </v-icon>-->
          <!--                </template>-->
          <!--              </v-expansion-panel-header>-->
          <!--              <v-expansion-panel-content>-->
          <!--                <div class="row">-->
          <!--                  <v-checkbox-->
          <!--                      v-model="categoryIds"-->
          <!--                      v-for="category in categories"-->
          <!--                      :key="category.id"-->
          <!--                      class="mx-3"-->
          <!--                      color="black"-->
          <!--                      :value="category.id"-->
          <!--                      :label="category.title"-->
          <!--                      @click="getChild(category.id)"-->
          <!--                  ></v-checkbox>-->
          <!--                </div>-->
          <!--              </v-expansion-panel-content>-->
          <!--            </v-expansion-panel>-->
          <!--          </v-expansion-panels>-->
          <!--        </v-col>-->
          <!--        <v-col class="background-color mt-3" cols="12">-->
          <!--          <v-expansion-panels>-->
          <!--            <v-expansion-panel-->
          <!--            >-->
          <!--              <v-expansion-panel-header disable-icon-rotate>-->
          <!--                <div v-if="!childIds[0]">-->
          <!--                  Sections-->
          <!--                </div>-->
          <!--                <div v-else>-->
          <!--                          <span style="font-size: 20px;font-family:Blinker-SemiBold; color:limegreen">{{-->
          <!--                              childIds.length-->
          <!--                            }} </span> Season Selected-->
          <!--                </div>-->
          <!--                <template v-if="childIds[0]" v-slot:actions>-->
          <!--                  <v-icon color="teal">-->
          <!--                    mdi-check-->
          <!--                  </v-icon>-->
          <!--                </template>-->
          <!--                <template v-else v-slot:actions>-->
          <!--                  <v-icon color="red">-->
          <!--                    mdi-alert-->
          <!--                  </v-icon>-->
          <!--                </template>-->
          <!--              </v-expansion-panel-header>-->
          <!--              <v-expansion-panel-content>-->
          <!--                <div class="row">-->
          <!--                  <div v-if="!categoryIds[0]">-->
          <!--                    Please choose Category first.-->
          <!--                  </div>-->
          <!--                  <v-checkbox-->
          <!--                      v-model="childIds"-->
          <!--                      v-for="child in children"-->
          <!--                      :key="child.id"-->
          <!--                      class="mx-3"-->
          <!--                      color="black"-->
          <!--                      :value="child.id"-->
          <!--                      :label="child.title"-->
          <!--                      @click="getTitle(child.id)"-->
          <!--                  ></v-checkbox>-->
          <!--                </div>-->
          <!--              </v-expansion-panel-content>-->
          <!--            </v-expansion-panel>-->
          <!--          </v-expansion-panels>-->
          <!--        </v-col>-->
          <!--        <v-col class="background-color mt-3" cols="12">-->
          <!--          <v-expansion-panels>-->
          <!--            <v-expansion-panel-->
          <!--            >-->
          <!--              <v-expansion-panel-header disable-icon-rotate>-->
          <!--                <div v-if="!titleIds[0]">-->
          <!--                  Topics-->
          <!--                </div>-->
          <!--                <div v-else>-->
          <!--                          <span style="font-size: 20px;font-family:Blinker-SemiBold; color:limegreen">{{-->
          <!--                              titleIds.length-->
          <!--                            }} </span> Topics Selected-->
          <!--                </div>-->
          <!--                <template v-if="titleIds[0]" v-slot:actions>-->
          <!--                  <v-icon color="teal">-->
          <!--                    mdi-check-->
          <!--                  </v-icon>-->
          <!--                </template>-->
          <!--                <template v-else v-slot:actions>-->
          <!--                  <v-icon color="red">-->
          <!--                    mdi-alert-->
          <!--                  </v-icon>-->
          <!--                </template>-->
          <!--              </v-expansion-panel-header>-->
          <!--              <v-expansion-panel-content>-->
          <!--                <div class="row">-->
          <!--                  <div v-if="!childIds[0]">-->
          <!--                    Please choose Seasons first.-->
          <!--                  </div>-->
          <!--                  <v-checkbox-->
          <!--                      v-model="titleIds"-->
          <!--                      v-for="title in titles"-->
          <!--                      :key="title.id"-->
          <!--                      class="mx-3"-->
          <!--                      color="black"-->
          <!--                      :value="title.id"-->
          <!--                      :label="title.title"-->
          <!--                  ></v-checkbox>-->
          <!--                </div>-->
          <!--              </v-expansion-panel-content>-->
          <!--            </v-expansion-panel>-->
          <!--          </v-expansion-panels>-->
          <!--        </v-col>-->
          <!--      </v-row>-->
          <v-row>
            <v-col cols="12" class="pl-3 mt-4">
              <div class="text-left title-custom-question-page" style="margin-bottom: 26px!important">
                Select Chapter and Section
              </div>
              <v-row>
                <v-col cols="4" v-for="category in categories" :key="category.id">
                  <div class="background-element-select py-2 px-4 text-left">
                    <v-checkbox
                        v-model="categoryIds"
                        class="mx-3 d-inline-block"
                        color="#6d6d6d"
                        :value="category.id"
                        :label="category.title"
                        @click="getChild(category.id)"
                    ></v-checkbox>
                    <v-icon class="mb-1"
                            @click="setChildWindowSituation(category.id,!childWindow[category.id])">
                      mdi-chevron-down
                    </v-icon>
                    <div v-if="childWindow[category.id]" class="child-category-window text-left">
                      <v-row>
                        <v-col cols="12"
                               v-for="child in children.filter((item)=> item.parent_id === category.id)"
                               :key="child.id">
                          <v-checkbox
                              class="custom-row-in-child-category"
                              v-model="childIds"
                              color="#6d6d6d"
                              :value="child.id"
                              :label="child.title"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              <v-btn
                  class="my-16 white--text text-capitalize"
                  color="#39bf88"
                  rounded
                  width="250"
                  height="60"
                  elevation="0"
                  @click="goToTest">Create Exam
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        <v-snackbar
            top
            v-model="snackbar"
        >
          <div v-html="msg">

          </div>
          <template v-slot:action="{ attrs }">
            <v-btn
                color="red"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <q-b-header-mobile></q-b-header-mobile>

        <exam-header-mobile @changeExamType="selectedExamType = $event"
                            :selectedExamType="selectedExamType"></exam-header-mobile>
        <exam-predefined v-if="selectedExamType === 'predefined'"></exam-predefined>
        <div v-if="selectedExamType === 'custom'" class="customized-exam-container">
          <v-row>
            <v-col cols="12">
              <div class="text-left title-custom-question-page-mobile">
                Exam Mode
              </div>
              <div class="background-element-exam">
                <v-radio-group
                    class="mx-auto"
                    row
                    v-model="examType"
                >
                  <v-radio
                      color="#555e66"
                      class="mx-auto mt-3"
                      label="Timed"
                      value=time
                  ></v-radio>
                  <v-radio
                      color="#555e66"
                      class="mx-auto mt-3"
                      label="Untimed"
                      value=liveTutor
                  ></v-radio>
                  <v-radio
                      color="#555e66"
                      class="mx-auto mt-3"
                      label="Tutor"
                      value=tutor
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-col>
            <v-col cols="12" class="pl-5">
              <div class="text-left title-custom-question-page-mobile" style="font-size: 20px;margin-bottom: 17px!important">
                Number of questions
              </div>
              <div class="background-element-exam">
                <v-slider
                    min="1"
                    class="my-0 py-0"
                    track-color="white"
                    track-color-filled="#555e66"
                    max="40"
                    color="#555e66"
                    v-model="questionNumber"
                    :thumb-size="20"
                    thumb-label="always"
                ></v-slider>
                <div style="margin-top:-18px!important">
                  <a href="#" @click="--questionNumber" class="mx-10 my-0 py-0">
                    <v-icon color="#a8a8a8">mdi-chevron-left</v-icon>
                  </a>
                  <span class="my-0 py-0" style="font-size: 16px;color:#6d6d6d">{{ questionNumber }}</span>
                  <a href="#" @click="++questionNumber" class="mx-10 my-0 py-0">
                    <v-icon color="#a8a8a8">mdi-chevron-right</v-icon>
                  </a>
                </div>

              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pl-3">
              <div class="text-left title-custom-question-page-mobile" style="margin-bottom: 26px!important">
                Question Type
              </div>
              <div class="background-element-exam">
                <v-row>
                  <v-col class="pl-8" cols="6">
                    <v-switch
                        v-model="all"
                        color="#39bf88"
                        :label="'All'+' ('+counter.all+')'"
                        :value=true
                        @click="setAll"
                    ></v-switch>
                    <v-switch
                        style="font-size: 10px!important"
                        v-model="marked"
                        color="#39bf88"
                        :label="'Marked'+' ('+counter.marked+')'"
                        :value=true
                        @click="setMark"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6">
                    <v-switch
                        v-model="unused"
                        color="#39bf88"
                        :label="'Unused'+' ('+counter.unused+')'"
                        :value=true
                        @click="setUnused"
                    ></v-switch>
                    <v-switch
                        v-model="incorrect"
                        color="#39bf88"
                        :label="'Incorrect'+' ('+counter.incorrect+')'"
                        :value=true
                        @click="setIncorrect"
                    ></v-switch>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <!--      <v-row>-->
          <!--        <v-col class="background-color mt-10" cols="12">-->
          <!--          <v-expansion-panels>-->
          <!--            <v-expansion-panel-->
          <!--            >-->
          <!--              <v-expansion-panel-header disable-icon-rotate>-->
          <!--                <div v-if="!categoryIds[0]">-->
          <!--                  Select Category-->
          <!--                </div>-->
          <!--                <div v-else>-->
          <!--                          <span style="font-size: 20px;font-family:Blinker-SemiBold; color:limegreen">{{-->
          <!--                              categoryIds.length-->
          <!--                            }} </span> Category Selected-->
          <!--                </div>-->
          <!--                <template v-if="categoryIds[0]" v-slot:actions>-->
          <!--                  <v-icon color="teal">-->
          <!--                    mdi-check-->
          <!--                  </v-icon>-->
          <!--                </template>-->
          <!--                <template v-else v-slot:actions>-->
          <!--                  <v-icon color="red">-->
          <!--                    mdi-alert-->
          <!--                  </v-icon>-->
          <!--                </template>-->
          <!--              </v-expansion-panel-header>-->
          <!--              <v-expansion-panel-content>-->
          <!--                <div class="row">-->
          <!--                  <v-checkbox-->
          <!--                      v-model="categoryIds"-->
          <!--                      v-for="category in categories"-->
          <!--                      :key="category.id"-->
          <!--                      class="mx-3"-->
          <!--                      color="black"-->
          <!--                      :value="category.id"-->
          <!--                      :label="category.title"-->
          <!--                      @click="getChild(category.id)"-->
          <!--                  ></v-checkbox>-->
          <!--                </div>-->
          <!--              </v-expansion-panel-content>-->
          <!--            </v-expansion-panel>-->
          <!--          </v-expansion-panels>-->
          <!--        </v-col>-->
          <!--        <v-col class="background-color mt-3" cols="12">-->
          <!--          <v-expansion-panels>-->
          <!--            <v-expansion-panel-->
          <!--            >-->
          <!--              <v-expansion-panel-header disable-icon-rotate>-->
          <!--                <div v-if="!childIds[0]">-->
          <!--                  Sections-->
          <!--                </div>-->
          <!--                <div v-else>-->
          <!--                          <span style="font-size: 20px;font-family:Blinker-SemiBold; color:limegreen">{{-->
          <!--                              childIds.length-->
          <!--                            }} </span> Season Selected-->
          <!--                </div>-->
          <!--                <template v-if="childIds[0]" v-slot:actions>-->
          <!--                  <v-icon color="teal">-->
          <!--                    mdi-check-->
          <!--                  </v-icon>-->
          <!--                </template>-->
          <!--                <template v-else v-slot:actions>-->
          <!--                  <v-icon color="red">-->
          <!--                    mdi-alert-->
          <!--                  </v-icon>-->
          <!--                </template>-->
          <!--              </v-expansion-panel-header>-->
          <!--              <v-expansion-panel-content>-->
          <!--                <div class="row">-->
          <!--                  <div v-if="!categoryIds[0]">-->
          <!--                    Please choose Category first.-->
          <!--                  </div>-->
          <!--                  <v-checkbox-->
          <!--                      v-model="childIds"-->
          <!--                      v-for="child in children"-->
          <!--                      :key="child.id"-->
          <!--                      class="mx-3"-->
          <!--                      color="black"-->
          <!--                      :value="child.id"-->
          <!--                      :label="child.title"-->
          <!--                      @click="getTitle(child.id)"-->
          <!--                  ></v-checkbox>-->
          <!--                </div>-->
          <!--              </v-expansion-panel-content>-->
          <!--            </v-expansion-panel>-->
          <!--          </v-expansion-panels>-->
          <!--        </v-col>-->
          <!--        <v-col class="background-color mt-3" cols="12">-->
          <!--          <v-expansion-panels>-->
          <!--            <v-expansion-panel-->
          <!--            >-->
          <!--              <v-expansion-panel-header disable-icon-rotate>-->
          <!--                <div v-if="!titleIds[0]">-->
          <!--                  Topics-->
          <!--                </div>-->
          <!--                <div v-else>-->
          <!--                          <span style="font-size: 20px;font-family:Blinker-SemiBold; color:limegreen">{{-->
          <!--                              titleIds.length-->
          <!--                            }} </span> Topics Selected-->
          <!--                </div>-->
          <!--                <template v-if="titleIds[0]" v-slot:actions>-->
          <!--                  <v-icon color="teal">-->
          <!--                    mdi-check-->
          <!--                  </v-icon>-->
          <!--                </template>-->
          <!--                <template v-else v-slot:actions>-->
          <!--                  <v-icon color="red">-->
          <!--                    mdi-alert-->
          <!--                  </v-icon>-->
          <!--                </template>-->
          <!--              </v-expansion-panel-header>-->
          <!--              <v-expansion-panel-content>-->
          <!--                <div class="row">-->
          <!--                  <div v-if="!childIds[0]">-->
          <!--                    Please choose Seasons first.-->
          <!--                  </div>-->
          <!--                  <v-checkbox-->
          <!--                      v-model="titleIds"-->
          <!--                      v-for="title in titles"-->
          <!--                      :key="title.id"-->
          <!--                      class="mx-3"-->
          <!--                      color="black"-->
          <!--                      :value="title.id"-->
          <!--                      :label="title.title"-->
          <!--                  ></v-checkbox>-->
          <!--                </div>-->
          <!--              </v-expansion-panel-content>-->
          <!--            </v-expansion-panel>-->
          <!--          </v-expansion-panels>-->
          <!--        </v-col>-->
          <!--      </v-row>-->
          <v-row>
            <v-col cols="12" class="pl-3 mt-4">
              <div class="text-left title-custom-question-page-mobile" style="margin-bottom: 26px!important">
                Select Chapter and Section
              </div>
              <v-row>
                <v-col cols="12" v-for="category in categories" :key="category.id">
                  <div class="background-element-select py-2 px-4 text-left">
                    <v-checkbox
                        v-model="categoryIds"
                        class="d-inline-block"
                        color="#6d6d6d"
                        :value="category.id"
                        :label="category.title"
                        @click="getChild(category.id)"
                    ></v-checkbox>
                    <v-icon class="mb-1"
                            @click="setChildWindowSituation(category.id,!childWindow[category.id])">
                      mdi-chevron-down
                    </v-icon>
                    <div v-if="childWindow[category.id]" class="child-category-window text-left">
                      <v-row>
                        <v-col cols="12"
                               v-for="child in children.filter((item)=> item.parent_id === category.id)"
                               :key="child.id">
                          <v-checkbox
                              class="custom-row-in-child-category"
                              v-model="childIds"
                              color="#6d6d6d"
                              :value="child.id"
                              :label="child.title"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mb-16">
            <v-col class="text-center">
              <v-btn
                  class="mt-4 mb-16 white--text text-capitalize"
                  color="#39bf88"
                  rounded
                  width="49%"
                  height="30"
                  elevation="0"
                  @click="goToTest">Create Exam
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import QBHeader from "../components/QBHeader";
import ExamHeader from "../components/exam/ExamHeader";
import ExamPredefined from "../components/exam/ExamPredefined";
import QBHeaderMobile from "../components/QBHeaderMobile.vue";
import ExamHeaderMobile from "../components/exam/ExamHeaderMobile.vue";

export default {
  components: {ExamHeaderMobile, QBHeaderMobile, ExamPredefined, ExamHeader, QBHeader},
  props: {
    options: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  data: () => ({
    screenType: null,
    snackbar: false,
    msg: '',
    counter: [],
    childWindow: [],
    questionNumber: 10,
    checked: [],
    categories: [],
    categoryIds: [],
    children: [],
    childIds: [],
    titles: [],
    type: [],
    examType: null,
    all: true,
    unused: false,
    incorrect: false,
    marked: false,
    selectedExamType: 'custom'
  }),
  mounted() {
    this.getScreenType()
    window.axios.get('api/categories').then((res) => {
      this.categories = res.data.data
    })
    window.axios.get('api/exam/questions/counter').then((res) => {
      this.counter = res.data.data
    })
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    onChange() {
      this.$emit('input', this.checked);
    },
    setAll() {
      this.marked = false
    },
    setMark() {
      this.all = false
    },
    setUnused() {
      this.incorrect = false
    },
    setIncorrect() {
      this.unused = false
    },
    setChildWindowSituation(categoryId, value) {
      this.childWindow = []
      this.$set(this.childWindow, categoryId, value)
    },
    getChild() {
      if (this.categoryIds.length) {
        window.axios.post('api/categories/child', {parentId: this.categoryIds}).then((res) => {
          this.children = res.data.data
          let _this = this
          this.childIds = []
          this.children.forEach(function (item) {
            _this.childIds.push(item.id)
          })
          this.getTitle()
        })
      } else {
        this.children = [];
        this.titles = [];
        this.titleIds = [];
        this.childIds = [];
      }
    },
    getTitle() {
      if (this.childIds.length) {
        window.axios.post('api/categories/title', {childIds: this.childIds}).then((res) => {
          this.titles = res.data.data
          let _this = this
          this.titleIds = []
          this.titles.forEach(function (item) {
            _this.titleIds.push(item.id)
          })
        })
      } else {
        this.titles = [];
        this.titleIds = [];
      }
    },
    goToTest() {
      if (
          !this.all &&
          !this.unused &&
          !this.incorrect &&
          !this.marked ||
          !this.examType
      ) {
        this.snackbar = true
        this.msg = 'Please make sure that you have selected the Exam mode and Question Type'
      } else {
        if (this.childIds) {
          window.axios.post('api/exam/create',
              {
                category_ids: this.childIds,
                all: this.all,
                unused: this.unused,
                incorrect: this.incorrect,
                marked: this.marked,
                type: this.examType,
                number: this.questionNumber
              }).then(() => {
            this.$router.push({name: 'Exam'});
          })
        } else {
          this.snackbar = true
          this.msg = 'Please make sure that you have selected the Chapter/Section'
          this.titles = [];
        }
      }
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'Blinker-Bold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-Regular';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-SemiBold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-SemiBold.ttf') format('truetype');
}

.font-big {
  font-size: 20px;
}

.v-input--checkbox input {
  size: 150px !important;
}

.background-color {
  background-color: #f2f3f7;
  border-radius: 20px;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  border-width: 0.7px;
  border-style: solid;
  border-radius: 10px;
  border-color: #c4c4c4;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #f2f3f7;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #555e66;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

@media (min-width: 600px) {
  #Mydivider {
    display: none;
  }
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 35%;
  top: 5%;
  width: 15px;
  height: 30px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.customized-exam-container {
  margin-right: 6.5vw !important;
  margin-left: 6.5vw !important;
}

.background-element-exam {
  background-color: #f2f3f7 !important;
  border-radius: 10px !important;
}

.background-element-select {
  background-color: #f2f3f7 !important;
  border-radius: 10px !important;
}

.title-custom-question-page {
  font-size: 16px !important;
  color: #a8a8a8;
  margin-left: 3px !important;
}

.title-custom-question-page-mobile{
  font-size: 20px !important;
  color: #a8a8a8;
  margin-left: 3px !important;
}

.child-category-window {
  position: absolute;
  z-index: 10;
  background-color: white;
  width: 300px !important;
  padding-left: 20px !important;
  padding-bottom: 40px !important;
  border-radius: 15px !important;
}

.custom-row-in-child-category {
  margin-bottom: -40px !important;
  padding-top: -10px !important;
}

</style>