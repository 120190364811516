<template>
  <div class="custom-container">
    <v-card class="exam-card pa-8">
      <v-data-table
          :headers="headers"
          :items="exams"
          :items-per-page="10"
          class="elevation-0"
      >
        <template v-slot:item.qr_code="{item}">
          <img class="mt-1" width="65px" height="65px" :src="item.qr_code" :alt="item.url">
        </template>
        <template v-slot:item.has_time="{item}">
          <v-icon v-if="item.has_time" color="success">mdi-check</v-icon>
          <v-icon v-else color="error">mdi-close</v-icon>
        </template>
        <template v-slot:item.has_result="{item}">
          <v-icon v-if="item.has_result" color="success">mdi-check</v-icon>
          <v-icon v-else color="error">mdi-close</v-icon>
        </template>
        <template v-slot:item.can_see_correct="{item}">
          <v-icon v-if="item.can_see_correct" color="success">mdi-check</v-icon>
          <v-icon v-else color="error">mdi-close</v-icon>
        </template>
        <template v-slot:item.questions="{item}">
          {{ item.questions.length }}
        </template>
        <template v-slot:item.start="{item}">
          <v-btn class="white--text text-capitalize" color="#34c288" @click="goToExam(item)">
           Let's Go!
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      exams: [],
      headers: [
        {text: 'id', value: 'id'},
        {text: 'title', value: 'title',align:'center'},
        {text: 'description', value: 'description',align:'center'},
        {text: 'QR', value: 'qr_code',align:'center'},
        {text: 'time', value: 'has_time'},
        {text: 'result', value: 'has_result'},
        {text: 'live', value: 'can_see_correct'},
        {text: 'questions(number)', value: 'questions',align:'center'},
        {text: 'start', value: 'start',align:'center'},
      ]
    }
  },
  name: "ExamPredefined",
  methods: {
    getExams() {
      window.axios.get('api/exam/template/index').then((res) => {
        this.exams = res.data.data
      }).catch()
    },
    goToExam(exam){
      this.$router.push('/exam/predefined/'+exam.url)
    }
  },
  mounted() {
    this.getExams()
  }
}
</script>

<style scoped>
.custom-container {
  margin: 0 15vw 0 16vw
}

.exam-card {
  border-radius: 35px;
}

.exam-background {
  background-color: #f2f3f7;
  border-radius: 10px 10px 10px 10px !important;
}
</style>