<template>
  <div>
    <v-row class="row-exam-header">
      <v-col class="text-center">
        <v-btn :color="selectedExamType === 'custom' ? '#39bf88' : '#f2f3f7'"
               width="250" height="60"
               rounded
               elevation="0"
               :class="selectedExamType === 'custom' ? 'text-capitalize exam-header-font-current' : 'text-capitalize exam-header-font'"
               @click="changeExamType('custom')"
        >
          Customized Exams
        </v-btn>
        <v-btn :color="selectedExamType === 'predefined' ? '#39bf88' : '#f2f3f7'"
               width="250" height="60"
               rounded
               elevation="0"
               :class="selectedExamType === 'predefined' ? 'text-capitalize exam-header-font-current' : 'text-capitalize exam-header-font'"
               @click="changeExamType('predefined')"
        >
          Self-Assessment Exams
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => {
    return {}
  },
  name: "ExamHeader",
  props: ['selectedExamType'],
  mounted() {
    console.log(this.selectedExamType)
  },
  methods: {
    changeExamType(type) {
      this.$emit('changeExamType', type)
    }
  }
}
</script>

<style scoped>
.exam-header-font-current {
  color: white !important;
  margin-right: 1.5vw !important;
  margin-left: 1.5vw !important;
}

.exam-header-font {
  color: #a8a8a8;
  margin-right: 1.5vw !important;
  margin-left: 1.5vw !important;
}

.row-exam-header {
  margin-top: 65px !important;
  margin-bottom: 45px !important
}
</style>